import Layout from 'containers/Layout';
import LoginForm from 'containers/LoginForm';
import contentful from 'lib/contentful';
import { SiteSettingsData } from 'lib/contentful/types';
import { GetStaticProps, InferGetStaticPropsType, NextPage } from 'next';
import { REVALIDATION_TIME } from '../lib/constants';

const Page: NextPage<InferGetStaticPropsType<typeof getStaticProps>> = ({ data }) => {
  const { siteSettings } = data;

  return (
    <Layout
      siteSettings={siteSettings}
      metaTags={[
        {
          name: 'robots',
          content: 'noindex',
        },
      ]}
      seo={{
        title: 'Login',
        description: 'Sanford Login',
        openGraphImageUrl: null,
      }}
    >
      <div className="w-full bg-gray-200 md:p-24">
        <div className="relative m-auto max-w-md rounded-lg bg-white p-6">
          <h1 className="mb-4 text-2xl uppercase">Log in</h1>
          <LoginForm />
        </div>
      </div>
    </Layout>
  );
};

export const getStaticProps: GetStaticProps<{
  data: { siteSettings: SiteSettingsData };
}> = async () => {
  const siteSettings = await contentful.siteSettings.getSiteSettings();

  return {
    revalidate: REVALIDATION_TIME.SHORT,
    props: {
      data: { siteSettings },
    },
  };
};

export default Page;
