import ApiError from 'components/error/ApiError';
import Input from 'components/form/TextInput';
import { useFormik } from 'formik';
import sanfordApi, { SFLoginRequest } from 'lib/sanford-api';
import { loginSchema } from 'lib/sanford-api/validation-schemas';
import { QUERIES } from 'queries';
import Router, { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import CheckBox from 'components/form/Checkbox';
import { FillButton, OutlineLinkButton } from 'components/Button';
import Link from 'next/link';

const LoginForm: React.FC = (props) => {
  const [rememberMe, setRememberMe] = useState(false); //todo: implement this.
  const router = useRouter();
  const queryClient = useQueryClient();
  const forgotPassword = () => {
    Router.push(`/forgot-password`);
  };
  const isExternalURL = (url: string) => {
    try {
      return new URL(url).origin !== location.origin;
    } catch (e) {
      return false;
    }
  };

  //only allow redirects that are internal.
  const validRedirectFromQuery = (url: string) => {
    if (url && !isExternalURL(url)) {
      return url;
    }
    return '';
  };

  const loginMutation = useMutation((newTodo) => sanfordApi.login(newTodo), {
    onMutate: async (data: SFLoginRequest) => {},

    onError: (err, variables, context) => {},

    onSuccess: (data) => {
      if (data.data.success) {
        const redirect =
          validRedirectFromQuery(router.query.redirect as string) || '/my-account/details';
        queryClient.invalidateQueries([QUERIES.CUSTOMER]);
        Router.push(redirect);
      }
    },
    onSettled: () => {},
    mutationKey: 'login',
  });

  const loginForm = useFormik<SFLoginRequest>({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      loginMutation.mutate(values);
    },
  });

  return (
    <div className="md:max-w-panel m-auto w-full">
      <div className="space-y-6 text-sf-blue-700 sm:mx-auto sm:w-full sm:max-w-md">
        <form onSubmit={loginForm.handleSubmit} className="space-y-6">
          <ApiError error={loginMutation.error} />
          <div className="space-y-2">
            <Input
              value={loginForm.values.email}
              onChange={loginForm.handleChange}
              onBlur={loginForm.handleBlur}
              id="email"
              label="Email"
              autoComplete="email"
              type="email"
              error={loginForm.touched.email && loginForm.errors.email}
            />
            <Input
              onBlur={loginForm.handleBlur}
              value={loginForm.values.password}
              onChange={loginForm.handleChange}
              id={`password`}
              label={'Password'}
              autoComplete={`current-password`}
              type={`password`}
              error={loginForm.touched.password && loginForm.errors.password}
            />
          </div>
          <div className="flex items-center">
            <CheckBox
              onBlur={() => {}}
              text="Remember me"
              onChange={(value) => setRememberMe(value.target.checked)}
              id="offersAccepted"
              name="offersAccepted"
              checked={Boolean(rememberMe)}
            ></CheckBox>
            <div className="ml-auto">
              <button type="button" onClick={forgotPassword} className="text-sm underline">
                Forgot password?
              </button>
            </div>
          </div>
          <FillButton fullWidth={true} type="submit" disabled={loginMutation.isLoading}>
            Login
          </FillButton>
        </form>
        <div className="flex items-center gap-2 text-sf-blue-700 before:w-full before:border-b-2 before:border-opacity-5 after:w-full after:border-b-2 after:border-opacity-5">
          <span className="flex-none text-sm">{`Don't have an account?`}</span>
        </div>
        <OutlineLinkButton internalUrl="/signup" fullWidth={true}>
          Sign up
        </OutlineLinkButton>
        <hr className="border-b border-sf-blue-700/5" />
        <p className="text-xs ">
          Your personal data will be used to support your experience throughout this website, to
          manage access to your account, and for other purposes described in our{' '}
          <Link href={'/privacy-policy'}>
            <a className="underline"> Privacy Policy</a>
          </Link>
          .
        </p>
      </div>
    </div>
  );
};

export default LoginForm;
