import React from 'react';
interface ErrorProps {
  error?: any;
}

const ApiError: React.FC<ErrorProps> = (props) => {
  const { error } = props;

  if (!error) return null;

  const message =
    error?.response?.data?.message || `Sorry, Something went wrong, please try again or contact us`;

  return (
    <div className="my-4 w-full rounded-md bg-red-300 p-4">
      <span className="text-red-700">{message}</span>
    </div>
  );
};

export default ApiError;
